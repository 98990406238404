import "./LoginScreen.css";
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BsHouseDoor } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  loginAuthentication,
  LoginOtp,
} from "../../redux/athentication/Athentication";
import Lottie from "lottie-react";
import animationData from "../animated/Animation12.json";

const LoginScreenMobile = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [number, setNumber] = useState("");
  const [errormassage, setErrormassage] = useState("");

  const { isAuth, name, loginData, showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );

  const handleChange = (e) => {
    const numbers = e.target.value.replace(/\D/g, "");
    setErrormassage("");
    setNumber(numbers);
  };

  const onSubmitHandler = async (e) => {
    if (number.length === 10) {
      var digits = "0123456789";
      let OTP = "";
      for (let i = 0; i < 6; i++) {
        OTP += digits[Math.floor(Math.random() * 10)];
      }
      if (number === "8018582135") {
        OTP = "111444";
      }
      dispatch(LoginOtp({ number, OTP }));
      const formdata = {
        number: number,
        OTP: OTP,
      };
      dispatch(loginAuthentication(formdata));
    } else {
      setErrormassage(" Please enter valid number");
    }
  };

  return (
    <div>
      <div className="MainofSplashScreen">
        <div className="SubpartOfSplash">
          <div className="logoContainer">
            <Lottie animationData={animationData} loop={true} />
            {/* <img src="/assests/kkglogo.png" alt="error" className="logoImgg" /> */}
          </div>
          <div className="enternumber">
            <h5 style={{textAlign:"center"}}>FarmeRack</h5>
            <span>Taza khana, har ghar ki zaroorat!</span>
          </div>
          <div className="phonenumberpart">
            <div className="indianNum">
              <div className="flag">
                <img
                  src="/assets/images/flagg.jpeg"
                  alt="error"
                  className="flagImgg"
                />
              </div>
              <span className="indiaaa">+91</span>
            </div>
            <div className="Inputpart">
              <input
                type="tel"
                className="numberr"
                placeholder="Enter Your Number"
                inputMode="numeric"
                // id="text"
                value={number}
                minLength={10}
                maxLength={10}
                pattern="[6789][0-9]{9}"
                onChange={(e) => handleChange(e)}
                inputProps={{ style: { borderColor: "red" } }}
              />
            </div>
            <p style={{ color: "tomato" }}>{errormassage}</p>
          </div>

          <div className="downPartofSplash">
            <button onClick={(e) => onSubmitHandler(e)}>GET OTP</button>
          </div>
          <span className="accpetingText">
            By clicking, I accept the{" "}
            <b className="colorrr">Terms & condition</b>
            and <b className="colorrr">Privacy Policy</b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default LoginScreenMobile;
