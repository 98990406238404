import { configureStore } from "@reduxjs/toolkit";
import CategoryReducer from "./category/CategorySlice";
import CartReducer from "./cart/CartSlice";
import SubCategoryReducer from "./subCategory/SubCategorySlice";
import AthenticationReducer from "./athentication/Athentication";
import OrderReducer from "./order/OrderSlice";
import HomeBannerReducer from "./AllBanners/AllBannersSlice";
import SubCategorySliderReducer from "./subcatSlider/SubCatSlider";
import FeatureInWeekReducer from "./AllBanners/FeatureInWeekSlice";
import TodayFeatureReducer from "./AllBanners/TodayFeatureSlice";
import SubscriptionCartReducer from "./Subscription/SubscriptionSlice";

export const store = configureStore({
  reducer: {
    Category: CategoryReducer,
    Cart: CartReducer,
    SubscriptionCart: SubscriptionCartReducer,
    SubCategory: SubCategoryReducer,
    Athentication: AthenticationReducer,
    Order: OrderReducer,
    HomeBanner: HomeBannerReducer,
    SubCategorySlider: SubCategorySliderReducer,
    FeatureInWeek: FeatureInWeekReducer,
    TodayFeature: TodayFeatureReducer,
  },
});
