import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

const SubCategorySection = (props) => {
  const { subCategoryTotal } = useSelector((store) => store.SubCategory);
  const { shopbyCategory } = useSelector((store) => store.Category);
  const { ProductTotal } = useSelector((store) => store.Cart);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [curresubctCategory, setCurresubctCategory] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [activeItemId, setActiveItemId] = useState(null);
  useEffect(() => {
    const currentCatId = props.categoryId;
    const currentSubCat = subCategoryTotal.filter(
      (subC) => subC.categoryId === currentCatId
    );
    setSubCategoryList(currentSubCat);
  }, [curresubctCategory, props.categoryId, ProductTotal, subCategoryTotal]);
  function itemClicked(e,itemId) {
    setActiveItemId(itemId);
 }
  return (
    <>
      <div className="left-box wow fadeInUp backsubcatcontainer subcatcontdestopversion">
        <div className="shop-left-sidebar">
          <div className="back-button">
            <h3>
              <i className="fa-solid fa-arrow-left" /> Back
            </h3>
          </div>

          <div className="accordion custome-accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>Sub Categories</span>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body">
                  <ul className="category-list custom-padding custom-height scroolulcontainer">
                    <li >
                      <div
                        className="form-check ps-0 m-0 category-list-box"
                        onClick={() => {
                          setCurresubctCategory("");
                          props.onFocus("");
                        }}

                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = "#eff9f8";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "";
                        }}
                      >
                        <div
                          className={
                            curresubctCategory === ""
                              ? "changecontainerimage"
                              : "changecontainerimage1"
                          }
                        >
                          <img
                            height={50}
                            width={50}
                            src="/assets/images/allsub.jpeg"
                            alt="img"
                            style={{
                              borderRadius: "50px",
                            }}
                          />
                        </div>

                        <label className="form-check-label alltext" htmlFor="fruit">
                          <span
                            className={
                              curresubctCategory === "" ? "nameActive" : "name"
                            }
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.onFocus("");
                              setCurresubctCategory("");
                            }}
                            onMouseOver={(e) => e.stopPropagation()}
                          >
                            All
                          </span>
                        </label>
                      </div>
                    </li>
                    {SubCategoryList.map((subcat, index) => (
                      <li key={index}
                      className={
                        curresubctCategory._id === subcat._id
                          ? "nameActive"
                          : "name"
                      }
                        onClick={(e) => itemClicked(e, subcat._id)}
                        style={activeItemId===subcat._id?{backgroundColor:"#eff9f8",borderLeft:"5px solid #b8ccc9"}:{}}
                        onMouseOver={(e) => {
                          e.target.style.backgroundColor = "#eff9f8";
                        }}
                        onMouseOut={(e) => {
                          e.target.style.backgroundColor = "";
                        }}
                      >
                        <div
                          className="form-check ps-0 m-0 category-list-box"
                          onClick={() => {
                            props.onFocus(subcat._id);
                            setCurresubctCategory(subcat);
                          }}
                          ///
                        >
                          <div
                            className={
                              curresubctCategory._id === subcat._id
                                ? "changecontainerimage"
                                : "changecontainerimage1"
                            }
                          >
                            <img
                              height={50}
                              width={50}
                              src={subcat.desktopImage}
                              alt="img"
                              style={{
                                borderRadius: "50px",
                                // mixBlendMode: "multiply",
                              }}
                            />
                          </div>

                          <label className="form-check-label" htmlFor="fruit">
                            <span
                              className={
                                curresubctCategory._id === subcat._id
                                  ? "nameActive"
                                  : "name"
                              }
                              style={{ cursor: "pointer" }}
                              onMouseOver={(e) => e.stopPropagation()}
                            >
                              {subcat.name}
                            </span>
                            {/* <span className="number">(15)</span> */}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="subcatcontmobversion">
        <div className="mobcontscroll wow fadeInUp">
          <div className="shop-left-sidebar">
            <div className="accordion custome-accordion">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button d-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span>Sub Categoriesguhijk</span>
                  </button>
                </h2>
                <div className="" aria-labelledby="headingOne">
                  <div className="">
                    <ul className="category-list custom-padding custom-height">
                      {SubCategoryList.map((subcat, index) => (
                        <li key={index}>
                          <div
                            className="form-check ps-0 m-0 category-list-box"
                            onClick={() => {
                              props.onFocus(subcat._id);
                              setCurresubctCategory(subcat);
                            }}
                          >
                            <div
                              className={
                                curresubctCategory._id === subcat._id
                                  ? "changecontainerimage"
                                  : "changecontainerimage1"
                              }
                            >
                              <img
                                height={50}
                                width={50}
                                src={subcat.desktopImage}
                                alt="img"
                                style={{
                                  borderRadius: "50px",
                                  // mixBlendMode: "multiply",
                                }}
                              />
                            </div>

                            <label className="form-check-label" htmlFor="fruit">
                              <span
                                className={
                                  curresubctCategory._id === subcat._id
                                    ? "nameActive"
                                    : "name"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {subcat.name}
                              </span>
                              {/* <span className="number">(15)</span> */}
                            </label>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubCategorySection;
