import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";
import { BsHouseDoor, BsPencilFill } from "react-icons/bs";
import MyDashBoard from "./dashboard/MyDashBoard";
import Order from "./dashboard/Order";
import Address from "./dashboard/Address";
import { useDispatch, useSelector } from "react-redux";
import {
  walletamountUpdate,
  getCartTotal,
  removefromCart,
} from "../../redux/cart/CartSlice";
import {
  authActions,
  walletUseUpdate,
  pageStatusChange,
  clientUpdate,
} from "../../redux/athentication/Athentication";
import { clearOrder } from "../../redux/order/OrderSlice";
import { FiSearch, FiUser } from "react-icons/fi";
import { BsTelephone, BsCart3 } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { FaRegUserCircle, FaUserEdit } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
import { BsArrowRepeat } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { LuShoppingBag } from "react-icons/lu";
import { Input, Modal } from "antd";
import Wallet from "../../pages/Login/Wallet";
import Subscription from "./Subscription";

const DashBoard = () => {
  const { CartItems, cartTotalAmount, ProductTotal } = useSelector(
    (state) => state.Cart
  );
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { categoryTotal } = useSelector((state) => state.Category);
  // const { groceresBrand } = useSelector((state) => state.Brand);

  const { isAuth, name, loginData, mob, email, showMassage, massageText } =
    useSelector((state) => state.Athentication);
  const [search, setSerch] = useState("");
  const [subCatSerchList, setSubCatSerchList] = useState("");
  const [catSerchList, setCatSerchList] = useState([]);
  const [prodSerchList, setProdSerchList] = useState([]);
  const [brandprodSerchList, setBrandProdSerchList] = useState("");
  const [showResults, setShowResults] = useState(false);

  const [fetchAllProducts, setFetchAllProducts] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("Hyderabad");
  const [fname, setFname] = useState();
  const [number, setNumber] = useState();
  const [eamail, setEamail] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  const [toggle, setToggle] = useState(true);

  const placeholderArray = [
    "I'm searching for Organic Vegetable..",
    "I'm searching for Organic Fruits..",
    "I'm searching for Dry Fruits...",
    "I'm searching for Chocolates desserts...",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholderArray.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [placeholderArray.length]);

  useEffect(() => {
    setFname(loginData.Name);
    setNumber(loginData.Mobile);
    setEamail(loginData.Email);
  }, [loginData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      name: fname,
      email: eamail,
      mobile: number,
      claintid: loginData._id,
    };

    const data = await dispatch(clientUpdate(formData));

    if (data.payload.success) {
      alert("user data updated");
      handleCancel()
    } else {
      alert("user data not updated");
      handleCancel()
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // current location google api
  const fetchAddress = (latitude, longitude) => {
    const apiKey = "AIzaSyAkOSe3Ns4VZ9MUqztuZj49GrUQ3mMqMag";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        if (data.results && data.results.length > 0) {
          // console.log(
          //   data.results[0],
          //   "data.results[0].formatted_address345689"
          // );
          // console.log(
          //   `${data.results[0].address_components[4].long_name},${data.results[0].address_components[4].short_name}`,
          //   "asdfg"
          // );

          setAddress(`${data.results[0].address_components[4].long_name}`);
        } else {
          setAddress("Address not found");
        }
      })
      .catch((error) => console.error(error));
  };
  // current location google api
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            fetchAddress(latitude, longitude);
          },
          (error) => {
            console.error("Error Code = " + error.code + " - " + error.message);
          }
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };
    getLocation();
  }, []);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);

  const removefromcarts = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };

  const searchchange = (e) => {
    const result = e.target.value;
    setSerch(result);

    if (result.length >= 2) {
      const filtersubcatbyserch = subCategoryTotal
        .filter((subcat) =>
          subcat.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 2); //Only the first 2 matching results are selected

      const filtercatdbyserch = categoryTotal
        .filter((cat) => cat.name.toLowerCase().match(result.toLowerCase()))
        .slice(0, 2);
      const filterProductbyserch = ProductTotal.filter((product) =>
        product.ItemName.toLowerCase().match(result.toLowerCase())
      ).slice(0, 2);
      setSubCatSerchList(filtersubcatbyserch);
      setCatSerchList(filtercatdbyserch);
      setProdSerchList(filterProductbyserch);
      // setBrandProdSerchList(filterBrandbyserch);
      setShowResults(true);
    }
  };

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  const handleSelection = (selectedItem) => {
    setSerch(selectedItem);
    setShowResults(false);
  };
  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  useEffect(() => {
    dispatch(getCartTotal());
  }, []);

  useEffect(() => {
    dispatch(walletamountUpdate(0));
    dispatch(walletUseUpdate(false));
  }, []);

  const logout = () => {
    dispatch(authActions.signout());
    dispatch(clearOrder());
    // localStorage.clear();
    navigate("/");
  };

  const handlescrolltop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <header className="pb-md-3 hedcontainermain cartheddexversion">
        <div className="topheaderbar">
          <marquee className="scrolling-text" direction="left">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="martext">
                Exciting News! 🎉 We're launching our brand-new website on 18th
                October! Stay tuned for exclusive offers, updates, and a whole
                new experience.
              </div>
            </div>
          </marquee>
        </div>
        {/* =============header top start===================== */}
        <div className="top-nav top-header sticky-header hedcontainermain">
          <div className="container-fluid-lg hedcontainermain1">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  <a className="web-logo nav-logo">
                    <Link to="/">
                      <img
                        // src="../assets/images/kisanlogo2.jpg"
                        src="../assets/images/farmerackLogo.png"
                        className="img-fluid blur-up lazyload"
                        alt="img"
                        style={{
                          height: '50px'
                        }}
                      />
                    </Link>
                  </a>

                  {/* ============middle start============== */}
                  <div className="middle-box dextop">
                    {isAuth === true ? (
                      <>
                        <Link to="/account">
                          <div
                            className="location-box"
                          // style={{
                          //   backgroundColor: "#01cb88",
                          //   color: "#fff",
                          //   borderColor: "#01cb88",
                          //   borderWidth: ".3px",
                          //   height: "50px",
                          // }}
                          >
                            <button
                              className="btn location-button"
                              data-bs-toggle="modal"
                              data-bs-target="#locationModal"
                            >
                              <span
                              // className="location-arrow"
                              // style={{
                              //   backgroundColor: "#01cb88",
                              //   color: "#fff",
                              //   borderColor: "#01cb88",
                              //   borderWidth: ".3px",
                              //   boxShadow:
                              //     "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                              // }}
                              >
                                <MdLocationOn
                                  style={{ color: "#f39636", fontSize: "20px" }}
                                />
                              </span>
                              <span
                                className="locat-name"
                                style={{
                                  color: "#fff",
                                  // fontFamily: "Poppins",
                                  paddingLeft: "4px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                              >
                                {address}
                              </span>
                              {/* <i className="fa-solid fa-angle-down" /> */}
                            </button>
                          </div>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/login">
                          <div
                            className="location-box"
                          // style={{
                          //   backgroundColor: "#01cb88",
                          //   color: "#fff",
                          //   borderColor: "#01cb88",
                          //   borderWidth: ".3px",
                          //   height: "50px",
                          // }}
                          >
                            <button
                              className="btn location-button"
                              data-bs-toggle="modal"
                              data-bs-target="#locationModal"
                            >
                              <span
                              // className="location-arrow"
                              // style={{
                              //   backgroundColor: "#01cb88",
                              //   color: "#fff",
                              //   borderColor: "#01cb88",
                              //   borderWidth: ".3px",
                              //   boxShadow:
                              //     "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                              // }}
                              >
                                <MdLocationOn
                                  style={{ color: "#f39636", fontSize: "20px" }}
                                />
                              </span>
                              <span
                                className="locat-name"
                                style={{
                                  color: "#fff",
                                  // fontFamily: "Poppins",
                                  paddingLeft: "4px",
                                  fontWeight: "500",
                                  textTransform: "capitalize",
                                }}
                              >
                                {address}
                              </span>
                              {/* <i className="fa-solid fa-angle-down" /> */}
                            </button>
                          </div>
                        </Link>
                      </>
                    )}

                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              backgroundColor: "#01cb88",
                              color: "#fff",
                              borderColor: "#01cb88",
                              borderWidth: ".3px",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            onChange={searchchange}
                          />

                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                            style={{
                              backgroundColor: "#01cb88",
                              borderColor: "#01cb88",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                          >
                            <FiSearch style={{ fontSize: "20px" }} />
                          </button>
                        </div>
                      </form>

                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* ============middle end============== */}

                  <div className="rightside-box">
                    <div className="search-full">
                      <div className="input-group">
                        <span className="input-group-text">
                          <FiSearch />
                        </span>
                        <input
                          type="text"
                          className="form-control search-type"
                          placeholder="Search here.."
                        />
                        <span className="input-group-text close-search">
                          <i data-feather="x" className="font-light" />
                        </span>
                      </div>
                    </div>
                    <ul className="right-side-menu">
                      <li className="right-side">
                        <div className="delivery-login-box">
                          <div className="delivery-icon">
                            <div className="search-box">
                              <i data-feather="search" />
                            </div>
                          </div>
                        </div>
                      </li>

                      <li className="right-side">
                        <div className="onhover-dropdown header-badge">
                          <Link to="/cart">
                            {CartItems.length === 0 ? (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                                <span
                                  className="position-absolute top-0 start-100 translate-middle badge"
                                  style={{
                                    backgroundColor: "#f39636",
                                    borderRadius: "50px",
                                  }}
                                >
                                  {CartItems.length}
                                </span>
                              </button>
                            )}
                          </Link>
                          {CartItems.length === 0 ? (
                            <></>
                          ) : (
                            <div className="onhover-div">
                              <ul className="cart-list">
                                {CartItems.map((data, index) => (
                                  <li
                                    className="product-box-contain"
                                    key={index}
                                  >
                                    <div className="drop-cart">
                                      <a className="drop-image">
                                        <img
                                          src={data.ImgUrl}
                                          className="blur-up lazyload"
                                          alt="img"
                                        />
                                      </a>
                                      <div className="drop-contain">
                                        <a>
                                          <h5>{data.ItemName}</h5>
                                        </a>
                                        <h6>
                                          <span>{data.Qty} x</span> ₹
                                          {data.Price}
                                        </h6>
                                        <button
                                          className="close-button close_button"
                                          onClick={() => removefromcarts(data)}
                                        >
                                          <GrFormClose />
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="price-box">
                                <h5>Total :</h5>
                                <h4 className="theme-color fw-bold">
                                  ₹{cartTotalAmount}
                                </h4>
                              </div>
                              <div className="button-group">
                                <Link to="/cart">
                                  <button className="btn btn-sm cart-button">
                                    View Cart
                                  </button>
                                </Link>

                                {isAuth ? (
                                  <>
                                    <Link to="/checkout">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="/login">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          {isAuth ? (
                            <Link to="/account">
                              <div
                                className="delivery-icon"
                                style={{ color: "#fff", fontFamily: "Poppins" }}
                              >
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />{" "}
                                {name}
                              </div>
                            </Link>
                          ) : (
                            <Link to="/login">
                              <div className="delivery-icon loginserch">
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />
                              </div>
                            </Link>
                          )}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          <Link>
                            <div
                              className="delivery-icon"
                              style={{ color: "#fff", fontFamily: "Poppins" }}
                            >
                              <LuShoppingBag
                                style={{
                                  fontSize: "22px",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                }}
                                className="mx-2"
                              />
                            </div>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {showMassage === true ? (
                  <>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "5vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0e947a",
                        borderRadius: "3px",
                      }}
                    >
                      <marquee scrolldelay="100">
                        <h6
                          style={{
                            fontSize: "1.1rem",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {massageText}
                        </h6>
                      </marquee>
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <div style={{ width: "92%" }}>
              <div style={{ padding: "6px" }} className="locationdeskversion">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {isAuth === true ? (
                    <>
                      <Link to="/account">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaLocationDot
                            style={{
                              fontSize: "15px",
                              color: "#00ab66",
                              fontWeight: "600",
                            }}
                          />
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              paddingLeft: "5px",
                              fontSize: "15px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {address}
                          </h6>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/login">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FaLocationDot
                            style={{
                              fontSize: "15px",
                              color: "#00ab66",
                              fontWeight: "600",
                            }}
                          />
                          <h6
                            style={{
                              color: "#000",
                              fontWeight: "600",
                              paddingLeft: "5px",
                              fontSize: "15px",
                              fontFamily: "Poppins",
                              fontStyle: "normal",
                              textTransform: "capitalize",
                            }}
                          >
                            {address}
                          </h6>
                        </div>
                      </Link>
                    </>
                  )}

                  <div
                    style={{
                      height: "35px",
                      width: "35px",
                      borderRadius: "20px",
                      backgroundColor: "#eff9f8",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <IoIosNotifications style={{ fontSize: "21px" }} />
                  </div>
                </div>
                <div className="navbar-top ">
                  <div className="middle-box mobile mt-2 ">
                    {/* <div className="location-box">
                      <button
                        className="btn location-button"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        <span className="location-arrow">
                          <TfiLocationPin />
                        </span>
                        <span className="locat-name">Your Location</span>
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </div> */}
                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              borderTopLeftRadius: "7px",
                              borderBottomLeftRadius: "7px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            ref={ref}
                            onChange={searchchange}
                          />
                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </form>
                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist ">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============header top end===================== */}
      </header>
      <section className="breadscrumb-section  pt-0 dashboardmobileversion">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12 ">
              <div className="breadscrumb-contain menumainhomebar">
                <h2>User Dashboard</h2>
                <nav>
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <BsHouseDoor className="mb-1 mx-1" />
                      </Link>
                    </li>
                    <li className="active" aria-current="page">
                      <span>
                        <AiOutlineCaretRight />
                      </span>
                      User Dashboard
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="user-dashboard-section section-b-space dashboardmobileversion">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xxl-3 col-lg-4">
              <div className="dashboard-left-sidebar">
                <div className="close-button d-flex d-lg-none">
                  <button className="close-sidebar">
                    <i className="fa-solid fa-xmark" />
                  </button>
                </div>
                <div className="profile-box">
                  <div className="cover-image">
                    <img
                      src="../assets/images/inner-page/cover-img.jpg"
                      className="img-fluid blur-up lazyload"
                      alt="img"
                    />
                  </div>
                  <div className="profile-contain">
                    <div className="profile-image">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <img
                          src="../assets/images/user.png"
                          className="blur-up lazyload update_img text-center"
                          alt="img"
                        />
                        <div className="cover-icon" onClick={showModal}>
                          <label htmlFor="actual-btn">
                            <BsPencilFill className="fa-solid fa-pen " />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="profile-name">
                      <h6 className="text-content"></h6>
                    </div>
                  </div>
                </div>
                <ul
                  className="nav nav-pills user-nav-pills"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-dashboard-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-dashboard"
                      type="button"
                      role="tab"
                      aria-controls="pills-dashboard"
                      aria-selected="true"
                    >
                      <i data-feather="home" />
                      DashBoard
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-wallet-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-wallet"
                      type="button"
                      role="tab"
                      aria-controls="pills-wallet"
                      aria-selected="false"
                    >
                      <i data-feather="shopping-bag" />
                      Wallet
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-subscription-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-subscription"
                      type="button"
                      role="tab"
                      aria-controls="pills-subscription"
                      aria-selected="false"
                    >
                      <i data-feather="shopping-bag" />
                      Subscription
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-address-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-address"
                      type="button"
                      role="tab"
                      aria-controls="pills-address"
                      aria-selected="false"
                    >
                      <i data-feather="map-pin" />
                      Address
                    </button>
                  </li>

                  <li
                    className="nav-item"
                    role="presentation"
                    onClick={(e) => logout(e)}
                  >
                    <button
                      className="nav-link"
                      data-bs-toggle="pill"
                      type="button"
                      role="tab"
                      aria-selected="false"
                    >
                      <i data-feather="shield" />
                      Log Out
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xxl-9 col-lg-8">
              {/* <button
                className="btn left-dashboard-show btn-animation btn-md fw-bold d-block mb-4 d-lg-none"
                onClick={() => setToggle(!toggle)}
              >
                Show Menu
              </button> */}
              {toggle ? (
                <>
                  <div className="mobileviewds mb-4">
                    <ul
                      className="nav nav-pills user-nav-pills"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active formobile"
                          id="pills-dashboard-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-dashboard"
                          type="button"
                          role="tab"
                          aria-controls="pills-dashboard"
                          aria-selected="true"
                        >
                          <i data-feather="home" />
                          DashBoard
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active formobile"
                          id="pills-dashboard-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-dashboard"
                          type="button"
                          role="tab"
                          aria-controls="pills-dashboard"
                          aria-selected="true"
                        >
                          <i data-feather="home" />
                          Wallet
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-address-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-address"
                          type="button"
                          role="tab"
                          aria-controls="pills-address"
                          aria-selected="false"
                        >
                          <i data-feather="map-pin" />
                          Address
                        </button>
                      </li>

                      <li
                        className="nav-item"
                        role="presentation"
                        onClick={(e) => logout(e)}
                      >
                        <button
                          className="nav-link"
                          data-bs-toggle="pill"
                          type="button"
                          role="tab"
                          aria-selected="false"
                        >
                          <i data-feather="shield" />
                          Log Out
                        </button>
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div className="dashboard-right-sidebar">
                <div className="tab-content" id="pills-tabContent">
                  <MyDashBoard />
                  {/* <Order /> */}
                  <Wallet />
                  <Subscription />
                  <Address />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="theme-option dashboardmobileversion">
        <div className="back-to-top" onClick={handlescrolltop}>
          <a id="back-to-top">
            <i className="fas fa-chevron-up" />
          </a>
        </div>
      </div>

      <div className="removeintab">
        <div className=" d-flex" style={{ alignItems: "center" }}>
          <div>
            <img
              src="../assets/images/user.png"
              alt="img"
              className="mvProfile"
            />
            <FaUserEdit
              style={{
                position: "absolute",
                left: "86px",
                fontSize: "20px",
                top: "65px",
                color: "#fff",
                fontWeight: "600",
              }}
              onClick={showModal}
            />
          </div>

          <div className="ps-3">
            <div className=" fw-bold fs-5">{loginData.Name}</div>
            <div style={{ color: "gray" }}>{loginData.Mobile}</div>
            <div style={{ color: "gray" }}>{loginData.Email}</div>
          </div>
        </div>
        {/* <div className="banner">
          <img src="/assets/images/FarmerAckPass.png" alt="img" width="100%" />
        </div> */}
        <div>
          <Link to="/addressList">
            <div className="order btn">
              <div className="bi-chat-right-dots">
                <span className="ps-3">Address Books</span>
              </div>
              <div className="bi-chevron-right text-danger "></div>
            </div>
          </Link>
          <Link to={"/orderScreen"}>
            <div className="order btn">
              <div className="bi-handbag">
                <span className="ps-3">Your Order</span>
              </div>
              <div className="bi-chevron-right text-danger"></div>
            </div>
          </Link>
          <Link to="/subscription">
            <div className="order btn">
              <div className="bi-chat-right-dots">
                <span className="ps-3">Subscriptions</span>
              </div>
              <div className="bi-chevron-right text-danger "></div>
            </div>
          </Link>
          <Link to="/wallet">
            <div className="order btn">
              <div className="bi-chat-right-dots">
                <span className="ps-3">Wallet</span>
              </div>
              <div className="bi-chevron-right text-danger "></div>
            </div>
          </Link>
          {/* <div className=" order btn">
            <div className="bi-heart">
              <span className="ps-3">Transactions History</span>
            </div>
            <div className="bi-chevron-right text-danger"></div>
          </div> */}
          {/* <div className="order btn">
            <div className="bi-geo-alt">
              <span className="ps-3">Your Coupons</span>
            </div>
            <div className="bi-chevron-right text-danger"></div>
          </div> */}
          <Link to="/about">
            <div className="order btn">
              <div className="bi-wallet">
                <span className="ps-3">About</span>
              </div>
              <div className="bi-chevron-right text-danger"></div>
            </div>
          </Link>
          <Link to="/privacy">
            <div className="order btn">
              <div className="bi-wallet">
                <span className="ps-3">Privacy Policy</span>
              </div>
              <div className="bi-chevron-right text-danger"></div>
            </div>
          </Link>
          <Link to="/terms-conditions">
            <div className="order btn">
              <div className="bi-wallet">
                <span className="ps-3">Terms & Conditions</span>
              </div>
              <div className="bi-chevron-right text-danger"></div>
            </div>
          </Link>
        </div>
        <div className="logbtncontainer " onClick={(e) => logout(e)}>
          <button className="btn btn-outline-danger w-100  logouttext">
            Logout
          </button>
        </div>
      </div>

      <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
          <li className="active">
            <Link to="/">
              <a>
                {/* <IoHomeOutline className=" icli" /> */}
                <Link to="/">
                  <img
                    src="../assets/images/farmerackLogo.png"
                    alt="img"
                    height="21px"
                    width="21px"
                  />
                </Link>
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                >
                  Home
                </span>
              </a>
            </Link>
          </li>
          <li className="mobile-category">
            <Link to="/CategoryScreen">
              <a>
                <BiCategoryAlt
                  style={{ color: "#404040", fontWeight: "600" }}
                  className="iconly-Category icli js-link"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Category
                </span>
              </a>
            </Link>
          </li>
          {/* <li>
            <Link to="/">
              <a className="search-box">
                <FiSearch className="iconly-Search icli" />
                <span>Search</span>
              </a>
            </Link>
          </li> */}
          {/* <li>
            <Link to="">
              <a className="notifi-wishlist">
                <BsArrowRepeat
                  style={{
                    color: "#404040",
                    fontWeight: "600",
                    fontSize: "21px",
                  }}
                  className="iconly-Heart icli"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Order Again
                </span>
              </a>
            </Link>
          </li> */}
          <li>
            <Link to="/cart">
              <div className="onhover-dropdown header-badge">
                <a className="position-relative header-wishlist">
                  <BsCart3
                    style={{ color: "#404040", fontWeight: "600" }}
                    className="iconly-Bag-2 icli fly-cate"
                  />
                  <span
                    style={{
                      color: "#767676",
                      fontWeight: "500",
                      fontSize: "11px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Cart
                  </span>
                  <span
                    className="position-absolute  translate-middle badge "
                    style={{
                      top: "5px",
                      left: "55px",
                      backgroundColor: "#f6f6f6",
                      color: "#04ab66",
                    }}
                  >
                    {CartItems.length}
                  </span>
                </a>
              </div>
            </Link>
          </li>
          <li>
            {/* <Link to="/login">
              <a className="notifi-wishlist">
                <FaRegUserCircle className="iconly-Heart icli" />
                <span>Account</span>
              </a>
            </Link> */}
            <li>
              {isAuth ? (
                <Link to="/account">
                  <a style={{ color: "#000" }} className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />
                    {/* <span style={{width:"100px"}}> {name}</span> */}
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              ) : (
                <Link to="/login">
                  <a className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              )}
            </li>
          </li>
        </ul>
      </div>

      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <h2 style={{ textAlign: "center" }}>Update Profile</h2>
        <div className="ps-3 mt-3">
          <div className=" fw-bold fs-5">
            <h4
              style={{ color: "#000", fontFamily: "Poppins", fontSize: "15px" }}
            >
              Name
            </h4>
            <Input
              type="text"
              value={fname}
              placeholder="Enter name"
              required
              onChange={(e) => setFname(e.target.value)}
              style={{ marginTop: "10px" }}
            />
          </div>
          <div style={{ color: "gray", marginTop: "10px" }}>
            <h4
              style={{ color: "#000", fontFamily: "Poppins", fontSize: "15px" }}
            >
              Mobile Number
            </h4>
            <Input
              type="tel"
              name="mobile"
              id="mobile"
              maxLength={10}
              minLength={10}
              value={number}
              // onChange={(e) => handleChange(e)}
              onChange={(e) => setNumber(e.target.value)}
              required
              placeholder="Enter Number"
              style={{ marginTop: "10px" }}
            />
          </div>
          <div style={{ color: "gray", marginTop: "10px" }}>
            <h4
              style={{ color: "#000", fontFamily: "Poppins", fontSize: "15px" }}
            >
              Email
            </h4>
            <Input
              value={eamail}
              onChange={(e) => setEamail(e.target.value)}
              placeholder="Enter email"
              style={{ marginTop: "10px" }}
            />
          </div>
        </div>
        <div className="updateprocontainer1">
          <div className="updateprocontainer">
            <button className="updateprotext" onClick={(e) => handleSubmit(e)}>
              Update Profile
            </button>
          </div>
        </div>
      </Modal>
      {/* Bg overlay Start */}
      <div className="bg-overlay" />
      {/* Bg overlay End */}
    </>
  );
};

export default DashBoard;
