import React from "react";
import { Link } from 'react-router-dom'

const EmptyCart = () => {
  return (
    <>
      <section className="section-404 section-lg-space1">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="image-404">
                <img
                  src="../assets/images/inner-page/emptycart.png"
                  className="img-fluid blur-up lazyload"
                  alt
                />
              </div>
            </div>
            <div className="col-12">
              <div className="contain-404">
                {/* <h3 className="text-content">
                The page you are looking for could not be found. The link to
                this address may be outdated or we may have moved the since
                you last bookmarked it.
              </h3> */}
                <Link to="/">
                  <button className="btn btn-md text-white theme-bg-color mt-4 mx-auto">
                    Back To Home Screen
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmptyCart;
