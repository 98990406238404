import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Baseurl } from "../../config/BaseUrl";

const initialState = {
  SubscriptionCartItems: localStorage.getItem("SubscriptionCartItems")
    ? JSON.parse(localStorage.getItem("SubscriptionCartItems"))
    : [],
  ProductTotal: localStorage.getItem("ProductTotal")
    ? JSON.parse(localStorage.getItem("ProductTotal"))
    : [],

  isProductAvailable: localStorage.getItem("ProductTotal") ? true : false,
  groceryProduct: [],
  groceryHotProduct: [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
  netPayable: 0,
  updateNetPayable: 0,
  cartTotalMrp: 0,
  cartTotalPrice: 0,
  cartPackQuantity: 0,
  cartHandling: 0,
  cartCouponDiscount: 0,
  CouponDiscountAmount: 0,
  cartCouponCode: "",
  cartCoupon: "",
  logisticsAmount: 40,
  usedWalletAmount: 0,
  productUpdated: false,
  Cartloading: true,
  //search
  subcatSearchProducts: [],
  searchSlugUrl: "",
  catSearchProducts: [],
  catsearchSlugUrl: "",
  SearchProducts: [],
  prosearchSlugUrl: "",
  vegCatProduct: [],
  freshfruitCatProduct: [],
  cleaningessentialCatProduct: [],
  pharamCatProduct: [],
  dryfruitCatProduct: [],
  chikenCatProduct: [],
  muttonCatProduct: [],
  fishCatProduct: [],
  isProductLoading: true,
};

export const getProduct = createAsyncThunk(
  "Product/getProduct",
  async (thunkAPI) => {
    try {
      const url = `${Baseurl}/api/v1/subcription-grocery/all`;
      const resp = await axios.get(url);
      return resp.data.grocery;
    } catch (error) {
      return thunkAPI.rejectWithValue("404 Not Found");
    }
  }
);

const SubscriptionCartSlice = createSlice({
  name: "SubscriptionCart",
  initialState,
  reducers: {
    InitialmovetoCart(state, action) {
      state.SubscriptionCartItems = action.payload;
    },
    updateCart(state, action) {
      state.SubscriptionCartItems = action.payload;
    },
    addtoCart(state, action) {
      const newProduct = action.payload;
      const existingProduct = state.SubscriptionCartItems[0]; // Assuming only one product can be stored
    
      if (existingProduct) {
        // Check if the existing product is the same as the new product
        if (existingProduct.packsizeId === newProduct.packsizeId) {
          // If it's the same product, increase the quantity
          state.cartTotalQuantity++;
          let newQuantity = existingProduct.Qty + 1;
    
          // Update the existing product's quantities and amounts
          existingProduct.Qty = newQuantity;
          existingProduct.TotalMrp = existingProduct.Mrp * newQuantity;
          existingProduct.TotalPrice = existingProduct.SellingPrice * newQuantity;
          existingProduct.TotalAmount = existingProduct.SellingPrice * newQuantity;
    
          // Update the cart's total amount
          state.cartTotalAmount = Number(state.cartTotalAmount) + newProduct.SellingPrice;
    
        } else {
          // If it's a different product, replace the existing one
          state.SubscriptionCartItems = [newProduct];
    
          // Reset cart quantities and amounts for the new product
          state.cartTotalQuantity = 1;
          state.cartPackQuantity = 1;
          state.cartTotalAmount = newProduct.SellingPrice;
        }
      } else {
        // If the cart is empty, just add the new product
        state.SubscriptionCartItems = [newProduct];
        state.cartTotalQuantity = 1;
        state.cartPackQuantity = 1;
        state.cartTotalAmount = newProduct.SellingPrice;
      }
    
      // Handle logistics amount
      state.logisticsAmount = state.cartTotalAmount >= 500 ? 0 : 40;
    
      // Calculate the net payable amount
      state.netPayable = state.cartTotalAmount + state.logisticsAmount;
    
      // Toggle loading and store updated cart in localStorage
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("SubscriptionCartItems", JSON.stringify(state.SubscriptionCartItems));
    },
    
    decrementCart(state, action) {
      let itemIndex = -1;
      itemIndex = state.SubscriptionCartItems.findIndex(
        (item) => item.packsizeId === action.payload.packsizeId
      );

      if (itemIndex >= 0) {
        if (state.SubscriptionCartItems[itemIndex].Qty > 1) {
          let quant = state.SubscriptionCartItems[itemIndex].Qty - 1;
          state.SubscriptionCartItems[itemIndex].Qty = state.SubscriptionCartItems[itemIndex].Qty - 1;
          state.SubscriptionCartItems[itemIndex].TotalMrp = Number(
            state.SubscriptionCartItems[itemIndex].Mrp * Number(quant)
          );

          state.SubscriptionCartItems[itemIndex].TotalPrice = Number(
            state.SubscriptionCartItems[itemIndex].SellingPrice * Number(quant)
          );
          state.SubscriptionCartItems[itemIndex].TotalAmount = Number(
            state.SubscriptionCartItems[itemIndex].SellingPrice * Number(quant)
          );
        } else if (state.SubscriptionCartItems[itemIndex].Qty === 1) {
          const nextSubscriptionCartItems = state.SubscriptionCartItems.filter(
            (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
          );
          state.cartPackQuantity--;
          state.SubscriptionCartItems = nextSubscriptionCartItems;
        }

        state.cartTotalAmount =
          Number(state.cartTotalAmount) - Number(action.payload.SellingPrice);

        if (state.cartTotalAmount >= 500) {
          state.logisticsAmount = 0;
        } else {
          state.logisticsAmount = 40;
        }

        state.netPayable =
          Number(state.cartTotalAmount) + Number(state.logisticsAmount);
        state.Cartloading = !state.Cartloading;
        localStorage.setItem("SubscriptionCartItems", JSON.stringify(state.SubscriptionCartItems));
      }
    },
    removefromCart(state, action) {
      const nextSubscriptionCartItems = state.SubscriptionCartItems.filter(
        (cartItem) => cartItem.packsizeId !== action.payload.packsizeId
      );
      state.cartPackQuantity--;
      state.SubscriptionCartItems = nextSubscriptionCartItems;
      state.Cartloading = !state.Cartloading;
      localStorage.setItem("SubscriptionCartItems", JSON.stringify(state.SubscriptionCartItems));
    },
    getCartTotal(state, action) {
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartTotalPrice = 0;
      const totalitem = state.SubscriptionCartItems;

      for (let i = 0; i < totalitem.length; i++) {
        state.cartTotalAmount =
          Number(state.cartTotalAmount) +
          Number(state.SubscriptionCartItems[i].TotalAmount);
        state.cartTotalMrp =
          Number(state.cartTotalMrp) + Number(state.SubscriptionCartItems[i].TotalMrp);
        state.cartTotalPrice =
          Number(state.cartTotalPrice) + Number(state.SubscriptionCartItems[i].TotalPrice);
      }

      if (state.cartTotalAmount >= 500) {
        state.logisticsAmount = 0;
      } else {
        state.logisticsAmount = 40;
      }
      state.netPayable =
        Number(state.cartTotalAmount) + Number(state.logisticsAmount);
    },

    clearCart(state, action) {
      state.SubscriptionCartItems = [];
      state.cartTotalQuantity = 0;
      state.cartTotalAmount = 0;
      state.netPayable = 0;
      state.cartTotalMrp = 0;
      state.cartPackQuantity = 0;
      state.Cartloading = !state.Cartloading;

      localStorage.setItem("SubscriptionCartItems", JSON.stringify(state.SubscriptionCartItems));
    },

    couponUpdate(state, action) {
      state.cartCoupon = action.payload;
    },
    setCouponDiscountAmount(state, action) {
      if (state.cartTotalAmount >= state.cartCoupon.MinAmount) {
        state.CouponDiscountAmount = action.payload;
      } else {
        state.CouponDiscountAmount = 0;
      }
    },
    setcartCouponDiscount(state, action) {
      state.cartCouponDiscount = action.payload;
    },

    //search
    setSubcatSearchProducts(state, action) {
      state.subcatSearchProducts = action.payload;
      state.catSearchProducts = [];
      state.SearchProducts = [];
    },
    setSearchSlugUrl(state, action) {
      state.searchSlugUrl = action.payload;
      state.catsearchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setCatSearchProducts(state, action) {
      state.catSearchProducts = action.payload;
      state.subcatSearchProducts = [];
      state.SearchProducts = [];
    },
    setCatsearchSlugUrl(state, action) {
      state.catsearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.prosearchSlugUrl = "";
    },
    setSearchProducts(state, action) {
      state.SearchProducts = action.payload;
    },
    setProsearchSlugUrl(state, action) {
      state.prosearchSlugUrl = action.payload;
      state.searchSlugUrl = "";
      state.catsearchSlugUrl = "";
    },

    setsearchEmplty(state, action) {
      state.searchSlugUrl = "";
      state.subcatSearchProducts = [];
      state.catsearchSlugUrl = "";
      state.catSearchProducts = [];
      state.prosearchSlugUrl = "";
      state.SearchProducts = [];
    },
    netpayableUpdate(state, action) {
      state.updateNetPayable = action.payload;
    },
    walletamountUpdate(state, action) {
      state.usedWalletAmount = action.payload;
    },
    setgetCart(state, action) {
      state.SubscriptionCartItems = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProduct.pending, (state) => {
        state.isProductLoading = true;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        if (action.payload) {
          const filteredProducts = action.payload.filter(
            (product) => product.PackSizes.length !== 0
          );

          state.ProductTotal = filteredProducts;
          state.groceryProduct = filteredProducts.filter(
            (product) => product.superCategoryId === "66cdb9aa4740b2cc1bbdc006"
          );
          state.groceryHotProduct = filteredProducts.filter(
            (product) => product.HotProducts === true
          );

          localStorage.setItem(
            "ProductTotal",
            JSON.stringify(state.ProductTotal)
          );
          localStorage.setItem(
            "groceryProduct",
            JSON.stringify(state.groceryProduct)
          );

          state.isProductLoading = false;
          state.isProductAvailable = true;
        }
      })
      .addCase(getProduct.rejected, (state) => {
        state.isProductLoading = false;
      });
  },
});

export const {
  updateCart,
  InitialmovetoCart,
  addtoCart,
  decrementCart,
  getCartTotal,
  clearCart,
  allProductUpdate,
  setSubcatSearchProducts,
  setSearchSlugUrl,
  setCatSearchProducts,
  setCatsearchSlugUrl,
  setSearchProducts,
  setProsearchSlugUrl,
  setsearchEmplty,
  couponUpdate,
  setCouponDiscountAmount,
  setcartCouponDiscount,
  removefromCart,
  netpayableUpdate,
  walletamountUpdate,
  primaeryChange,
  setgetCart,
} = SubscriptionCartSlice.actions;
export default SubscriptionCartSlice.reducer;
