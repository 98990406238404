import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";

const SubCategorySectionMobile = (props) => {
  const { subCategoryTotal } = useSelector((store) => store.SubCategory);

  const { shopbyCategory } = useSelector((store) => store.Category);
  const { ProductTotal } = useSelector((store) => store.Cart);
  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [curresubctCategory, setCurresubctCategory] = useState("");
  const [currentCategory, setCurrentCategory] = useState("");
  const [activeItemId, setActiveItemId] = useState(null);
  const [categoryDeskImg, setCategoryDeskImg] = useState('')

  useEffect(() => {
    if (props) {
      setCategoryDeskImg(props.categroyDeskImage)
    }

  }, [props])



  useEffect(() => {
    const currentCatId = props.categoryId;
    const currentSubCat = subCategoryTotal.filter(
      (subC) => subC.categoryId === currentCatId
    );
    setSubCategoryList(currentSubCat);
  }, [curresubctCategory, props.categoryId, ProductTotal, subCategoryTotal]);
  function itemClicked(e, itemId) {
    setActiveItemId(itemId);
  }

  return (
    <div className="cat_Sub_text">
      <div className="sub_containerrrrr">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
            justifyContent: "center",
          }}
        >
          {
            categoryDeskImg &&
            <div className="img_sub_cat">
              <div>
                <img
                  src={categoryDeskImg}
                  // src="../assets/images/freshveg.jpg"
                  // alt="error"
                  className="img_subbb"
                />
              </div>
              <div className="cat_nameeee"
                // className={
                //   curresubctCategory === "" ? "nameActive" : "name"
                // }
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.onFocus("");
                  setCurresubctCategory("");
                }}
                onMouseOver={(e) => e.stopPropagation()}

              >All</div>
            </div>
          }
        </div>

        {SubCategoryList.map((subcat, index) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "10px",
              justifyContent: "center",
            }}
            key={index}
            className={
              curresubctCategory._id === subcat._id
                ? "nameActive"
                : "name"
            }

            onClick={(e) => itemClicked(e, subcat._id)}
            // style={activeItemId === subcat._id ? { backgroundColor: "#eff9f8", borderLeft: "5px solid #b8ccc9" } : {}}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "#eff9f8";
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "";
            }}
          >
            <div className="img_sub_cat">
              <div
                onClick={() => {
                  props.onFocus(subcat._id);
                  setCurresubctCategory(subcat);
                }}
              >
                <img
                  //   src="../assets/images/freshveg.jpg"
                  src={subcat.desktopImage}
                  alt="error"
                  className="img_subbb"
                />
              </div>
              <div
                className="cat_nameeee"
                onMouseOver={(e) => e.stopPropagation()}
              >
                {/* {subcat.name} */}
                {subcat.name.length > 6
                  ? subcat.name.slice(0, 6) + "..."
                  : subcat.name}
              </div>
            </div>
          </div>
        ))}

        <div className="to_give_margine"></div>
      </div>
    </div>
  );
};

export default SubCategorySectionMobile;
