import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import { Modal, ModalHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  addressModelStatus,
  currAdress,
  editaddressModelStatus,
  noaddressUpdate,
  updateAddress,
} from "../redux/athentication/Athentication";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import AddAddress from "../component/account/dashboard/address/AddAddress";
import EditAddress from "../component/account/dashboard/address/EditAddress";

const AddressList = () => {
  const { currentAddress, address, addressmodel, editaddressmodel, clientid } =
    useSelector((store) => store.Athentication);

  const [model, setModel] = useState(false);
  const [editmodel, setEditModel] = useState(false);
  const [editableAddress, setEditableAddress] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //   const deleteClick = async (e, id) => {
  //     const data = await dispatch(addressDelete(id));
  //   };

  useEffect(() => {}, []);

  const editaddress = (e, data) => {
    const formData = {
      address: data,
    };
    setEditableAddress(formData);
    dispatch(editaddressModelStatus(true));
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
  };

  const deleteClick = async (e, addressId) => {
    e.preventDefault();
    try {
      const formData = {
        ClientId: clientid,
        addressId: addressId,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/client/deleteclientsingleaddress`;
      const addAddress = await axios.put(url, formData, config);

      if (addAddress.data.success) {
        const lastaddress = addAddress.data.client;
        const currentAddress = lastaddress.Addresses;
        dispatch(updateAddress(currentAddress));
        if (currentAddress.length === 0) {
          dispatch(noaddressUpdate());
        }
      }
    } catch (error) {}
  };
  return (
    <div>
      <Modal
        size="md"
        isOpen={addressmodel}
        toggle={() => dispatch(addressModelStatus(true))}
      >
        <ModalHeader>
          <AddAddress />
        </ModalHeader>
      </Modal>

      <Modal
        size="md"
        isOpen={editaddressmodel}
        toggle={() => dispatch(editaddressModelStatus(true))}
      >
        <ModalHeader>
          <EditAddress addressobject={editableAddress} />
        </ModalHeader>
      </Modal>

      <section className="p-2 px-4">
        <h3>Saved Address</h3>
        {address && address.length > 0 ? (
          <>
            {" "}
            <div className="scrollbarCards mt-3" style={{ overflow: "scroll" }}>
              <style>
                {`
                   .scrollbarCards::-webkit-scrollbar {
                          display: none; 
                      }
                  `}
              </style>
              {address &&
                address.map((data, index) => (
                  <div className="card w-100 mb-2" key={index}>
                    <div className="card-header d-flex justify-content-between">
                      <span>{data.Type}</span>
                      <span className="bi bi-pencil-square"></span>
                    </div>
                    <div className="card-body p-1 px-4">
                      <div>{data.HNo + "," + data.StreetDet}</div>
                      <div>{data.Pincode}</div>
                      <div>+91 {data.Number}</div>
                    </div>
                    <div
                      className="card-footer p-1"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <button
                        className="btn btn-success text-center w-50"
                        onClick={(e) => editaddress(e, data)}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-danger text-center w-50"
                        onClick={(e) => deleteClick(e, data._id)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="empty-state ">
              <div className="emptydexversioncon">
                <img
                  src="../assets/images/address.jpg"
                  className="empty-stateimg"
                  alt="No Address"
                />
                <p
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    paddingTop: "7px",
                  }}
                >
                  No addresses available.
                </p>
              </div>
            </div>
          </>
        )}

        {/* <div
          className="  bg-success text-light  z-1"
          style={{ marginBottom: "60px" }}
          onClick={() => dispatch(addressModelStatus(true))}
        >
          <button className="bi bi-plus text-light btn btn-outline-success w-100">
            Add New Address
          </button>
        </div> */}
      </section>
    </div>
  );
};

export default AddressList;
